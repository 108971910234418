<template>
  <div>
    <div
      class="group bg-white hover:bg-primary-highlight transition ease-in-out duration-100 rounded shadow-md text-secondary p-5 flex flex-col relative"
    >
      <h2 class="text-lg uppercase">Return Codes</h2>
      <div class="relative flex items-baseline my-4">
        <div
          class="absolute inset-0 bg-white transition ease-in-out duration-100 group-hover:bg-primary-highlight flex items-center justify-center"
          v-show="viewState == 'Loading'"
        >
          <Spinner :color="'text-black'" :size="10" />
        </div>
        <div
          class="absolute inset-0 bg-white transition ease-in-out duration-100 group-hover:bg-primary-highlight flex flex-col justify-center"
          v-if="viewState == 'Error'"
        >
          <span class="leading-none font-bold text-2xl text-red-600 mb-2"
            >Error</span
          >
          <span class="leading-none text-lg text-red-600"
            >{{ error.status }}: {{ error.statusText }}</span
          >
        </div>
        <span class="text-5xl">{{ codeCount || "--" }}</span>
        <span class="uppercase text-lg ml-2">Codes Logged</span>
      </div>
      <router-link
        class="absolute inset-0 hidden group-hover:block"
        to="/tools/returncodes"
      >
      </router-link>
      <div class="flex items-center justify-end text-lg">
        <button
          class="z-10 border border-secondary hover:bg-secondary hover:text-primary-highlight transition-colors duration-100 ease-in-out pl-2 pr-3 py-1 rounded flex items-center"
          @click="showingQuickLogPanel = true"
        >
          <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
          >
            <path d="M21 8v13H3V8M1 3h22v5H1zM10 12h4" />
          </svg>
          <span>Quick Log</span>
        </button>
      </div>
    </div>

    <portal to="overlay-outlet">
      <Panel
        :showing="showingQuickLogPanel"
        @close="showingQuickLogPanel = false"
        title="Quick Log Return Code"
      >
        <QuickLogReturnCode @complete="returnCodeLogged" />
      </Panel>
    </portal>
  </div>
</template>

<script>
const QuickLogReturnCode = () =>
  import("@/components/Dashboard/ReturnCodes/QuickLogReturnCode.vue");
const Panel = () => import("@/components/Panel.vue");
const Notification = () => import("@/components/Notification.vue");
const Spinner = () => import("@/components/Spinner.vue");

export default {
  name: "ReturnCodeWidget",
  components: {
    Panel,
    QuickLogReturnCode,
    Spinner,
  },
  data() {
    return {
      viewState: "Idle",
      codeCount: 0,
      showingQuickLogPanel: false,
      error: null,
    };
  },
  async mounted() {
    this.viewState = "Loading";
    try {
      let codeResult = await this.ReturnCodeService.getLoggedCodes(1, 1);
      this.codeCount = codeResult.count;
    } catch (error) {
      console.error("ReturnCodeWidget error", error);
      this.error = error;
    } finally {
      this.viewState = "Idle";
    }
  },
  methods: {
    async returnCodeLogged() {
      this.showingQuickLogPanel = false;
    },
  },
};
</script>

<style  scoped>
.card-link {
  &:hover {
    text-decoration: underline !important;
    cursor: pointer;
  }
}
.enquiries-container {
  flex-direction: row;
}
@media screen and (min-width: 768px) and (max-width: 1225px) {
  .enquiries-container {
    flex-direction: column;
  }
}
</style>
